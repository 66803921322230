<template>
  <div>
    <Navbar mode="solid" class="fixed w-full top-0" />
    <main class="page-main">
      <div class="wrapper">
        <h1>Privacy Policy</h1>
        <span class="updated-at">10. Aug 2021</span>
        <h2>Foreword</h2>
        <p>
          Thank you for using Travel Afrique and being part of our community. At
          Travel Afrique, (“Travel Afrique”, “we”, “us”, or “our”) we are
          committed to protecting your personal information and your right to
          privacy.
        </p>
        <p>
          If you have any questions or concerns about our policy, or our
          practices with regards to your personal information, please contact us
          via contact(at)travelafrique.com.
        </p>
        <p>
          The information shared with us helps us provide you with a great
          experience on Travel Afrique's websites. We have a team dedicated to
          protecting all personal information that we collect and helping to
          ensure that personal information is handled properly.
        </p>
        <p>
          When you visit our website and use our services, you entrust us with
          your personal information. We take your privacy seriously. In this
          privacy notice, we describe our Privacy Policy ("Privacy Policy" or
          "Policy"). We seek to explain to you in the clearest way possible what
          information we collect, how we use it and what rights you have in
          relation to it. If there are any terms in this Privacy Policy that you
          do not agree with, please discontinue the use of our websites and our
          services.
        </p>
        <p>
          This Policy applies to all information collected through our website,
          and/or any related services, sales, marketing or events (we refer to
          them collectively in this Privacy Policy as the “Sites”).
        </p>
        <p>
          Please read this Privacy Policy carefully as it will help you make
          informed decisions about sharing your personal information with us.
        </p>
        <h2>1. What information do we collect</h2>
        <h3>1.1 Personal information you disclose to us</h3>
        <p>
          We collect personal information that you provide to us such as name,
          address, contact information, passwords and security data, payment
          information, and social media login data. We collect personal
          information that you voluntarily provide to us when registering at the
          Sites expressing an interest in obtaining information about us or our
          products and services, when participating in activities on the Sites
          or otherwise contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Sites, the choices you make and the
          products and features you use. The personal information we collect can
          include the following:
        </p>
        <ul>
          <li>
            <strong>Name and contact data:</strong> We collect your first and
            last name, email address, postal address, phone number, and other
            similar contact data.
          </li>
          <li>
            <strong>Credentials:</strong> We collect passwords, and similar
            security information used for authentication and account access.
          </li>
          <li>
            <strong>Payment data:</strong> We collect data necessary to process
            your payment if you make purchases, such as your payment instrument
            number (such as a credit card number), and the security code
            associated with your payment instrument. All payment data is stored
            by our payment processor and you should review its Privacy Policies
            and contact the payment processor directly to respond to your
            questions.
          </li>
        </ul>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <h3>1.2 Information automatically collected</h3>
        <p>
          Some information – such as IP address and/or browser and device
          characteristics – is collected automatically when you visit our Sites.
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Sites. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Sites and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Sites, and for our internal analytics and reporting purposes.
        </p>
        <h3>1.3 Information collected from other sources</h3>
        <p>
          We may collect limited data from public databases, marketing partners,
          social media platforms, and other outside sources.
        </p>
        <p>
          We may obtain information about you from other sources, such as public
          databases, joint marketing partners, social media platforms (such as
          Facebook), as well as from other third parties. Examples of the
          information we receive from other sources include:
        </p>
        <ul>
          <li>
            Social media profile information (your name, gender, birthday,
            email, current city, state and country, user identification numbers
            for your contacts, profile picture URL and any other information
            that you choose to make public);
          </li>
          <li>
            Marketing leads and search results and links, including paid
            listings (such as sponsored links).
          </li>
        </ul>
        <p>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent.
        </p>
        <p>
          We use personal information collected via our Sites for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            <strong>To facilitate account creation and login process:</strong>
            If you choose to link your account with us to a third party account
            (such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and login process. See section seven below headed
            “How do we handle your social logins” for further information.
          </li>
          <li>
            <strong
              >To send you marketing and promotional communications:</strong
            >
            We and/or our third-party marketing partners may use the personal
            information you send to us for marketing purposes, if this is in
            accordance with your marketing preferences. You can opt-out of our
            marketing emails at any time (see in section 11, “What are your
            privacy rights” below).
          </li>
          <li>
            <strong>To send administrative information to you:</strong> We may
            use your personal information to send you product, service and new
            feature information and/or information about changes to our terms,
            conditions, and policies.
          </li>
          <li>
            <strong>Fulfill and manage your orders:</strong> We may use your
            information to fulfill and manage your orders, payments, returns,
            and exchanges made through the sites.
          </li>
          <li>
            <strong>Deliver targeted advertising to you:</strong> We may use
            your information to develop and display content and advertising (and
            work with third parties who do so) tailored to your interests and/or
            location and to measure its effectiveness.
          </li>
          <li>
            <strong>Request feedback:</strong> We may use your information to
            request feedback and to contact you about your use of our Sites.
          </li>
          <li>
            <strong>To protect our Sites:</strong> We may use your information
            as part of our efforts to keep our sites safe and secure (for
            example, for fraud monitoring and prevention).
          </li>
          <li>
            <strong>To enforce our terms, conditions and policies.</strong>
          </li>
          <li>
            <strong>To respond to legal requests and prevent harm:</strong> If
            we receive a subpoena or other legal request, we may need to inspect
            the data we hold to determine how to respond.
          </li>
          <li>
            <strong>For other business purposes:</strong> We may use your
            information for other business purposes, such as data analysis,
            identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our sites,
            products, services, marketing and your experience.
          </li>
        </ul>
        <h2>3. Will your information be shared with anyone?</h2>
        <p>
          We only share information with your consent, to comply with laws, to
          protect your rights, or to fulfill business obligations. We may
          process or share data based on the following legal basis:
        </p>
        <ul>
          <li>
            <strong>Consent:</strong> We may process your data if you have given
            us specific consent to use your personal information in a specific
            purpose.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> We may process your data when
            it is reasonably necessary to achieve our legitimate business
            interests.
          </li>
          <li>
            <strong>Performance of a contract:</strong> Where we have entered
            into a contract with you, we may process your personal information
            to fulfill the terms of our contract.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may disclose your information
            where we are legally required to do so in order to comply with
            applicable law, governmental requests, a judicial proceeding, court
            order, or legal process, such as in response to a court order or a
            subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).
          </li>
          <li>
            <strong>Vital interests:</strong> We may disclose your information
            where we believe it is necessary to investigate, prevent, or take
            action regarding potential violations of our policies, suspected
            fraud, situations involving potential threats to the safety of any
            person and illegal activities, or as evidence in litigation in which
            we are involved.
          </li>
          <li>
            <strong
              >Vendors, consultants and other third-party service
              providers:</strong
            >
            We may share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Sites,
            which will enable them to collect data about how you interact with
            the sites over time. This information may be used to, among other
            things, analyze and track data, determine the popularity of certain
            content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your
            information with third parties for their promotional purposes.
          </li>
          <li>
            <strong>Business Transfers:</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
        </ul>
        <h2>4. Who will your information be shared with?</h2>
        <p>
          We only share and disclose your information with the third parties
          listed below. We have categorized each party so that you may be easily
          understand the purpose of our data collection and processing
          practices. If we have processed your data based on your consent and
          you wish to revoke your consent, please contact us.
        </p>
        <ul>
          <li><strong>Invoice and billing:</strong> Stripe</li>
          <li><strong>Retargeting platforms:</strong> Facebook Custom Audience, Facebook Remarketing, Google Ads Remarketing and Google Analytics Remarketing</li>
          <li><strong>Social media sharing and advertising:</strong> Facebook advertising, Facebook social plugins and Instagram advertising</li>
          <li><strong>Web and mobile analytics:</strong> Google Analytics, Google Tag Manager</li>
        </ul>
        <h2>5. Do we use cookies and other tracking technologies?</h2>
        <p>When you visit our website, information may be stored on your computer in the form of a cookie. This paragraph describes the cookies we use and explains why we use cookies and how we use the information we collect. It also explains how cookies enable the proper functioning of our website and app and why you may not be able to use the full functionality of the website if you disable the use of cookies. By visiting our website without changing
your preferences, you consent to the use of all cookies and other data collection tools; however, if you decline to use these cookies, you may change your cookie-related preferences at any time. All companies and/or networks with which we work have their own privacy policies and procedures in place for their users to opt-out of tracking and behavioral targeting through advertisements or the opt-out procedures set out in the “Controlling and Deleting Cookies” section.</p>
<h3>5.1 What are cookies</h3>
<p>Cookies are small text files that contain data that are downloaded to your computer or mobile device when you visit certain Internet pages. The cookies are then sent (back) to the original website or another website that recognizes the cookie each time you visit it. The use of cookies is widely used to make our website work or function more efficiently and to provide information to the owners of the website. Cookies have many different purposes:
They allow you to switch efficiently between different pages, store your preferences and generally improve the user experience. Cookies allow us to determine whether you have visited our website before, for example, or whether you are a new visitor. They can also help ensure that the ads you see online are relevant and of interest to you.</p>
      <p>There are two main types of cookies:</p>
      <ul>
        <li>“First-party cookies are sent directly from us to your computer or mobile device;</li>
        <li>Third party cookies are sent by third parties on our behalf. We may use third-party cookies for functionality, performance/analytics, advertising/tracking, and social networking purposes.</li>
      </ul>
      <p>Cookies may be stored on your computer or mobile device for different periods of time. Some cookies are session cookies. These are only stored temporarily during a browser session and are deleted when the browser is closed.</p>
      <p>Other cookies are so-called persistent cookies, which means that they are stored on your computer or mobile device for a certain period of time and are not deleted when your browser is closed. They can be used by our website to re</p>
    <h3>5.2 Why do we use cookies?</h3>
    <p>We use cookies for several reasons, including the following:</p>
    <ul>
      <li>to facilitate and optimize the functioning of our website and access to it;</li>
      <li>to track traffic flow and patterns associated with our website;</li>
      <li>to keep track of the total number of visitors to our website and the types of Internet browsers (e.g. Firefox, Chrome or Internet Explorer) and operating systems (e.g. Windows or Mac OS) used by our visitors;</li>
      <li>to monitor and continuously improve the performance of our website;</li>
      <li>to personalize and enhance your online experience.</li>
    </ul>
    <h3>5.3 What types of cookies do we use?</h3>
    <p>The types of cookies we and our partners use in connection with our website can be divided into five categories: “Essential Cookies”, “Functional Cookies”, “Performance and Analysis Cookies”, “Advertising and Tracking Cookies” and “Social Media Cookies”. We have collected some additional information about each category and the purposes of the cookies that we and third parties set in the following table.</p>
    <h3>5.4 Required cookies for our essential purpose</h3>
    <p>These cookies are critical to the delivery of our website and all services available through the
website. These cookies also support functions such as access to protected areas. Without these cookies, services you have requested, such as transaction pages and secure login accounts, would not be possible.</p>
      <h3>5.5 Cookies for functionality</h3>
      <p>Functionality cookies collect information about your choices and allow us to tailor our site to you. These cookies mean that we can provide you with our services according to your preferences when you continue to use or visit the site. These cookies enable us, for example:</p>
      <ul>
        <li>to remember the settings you have made, such as layout, text size, settings and colors;</li>
        <li>to show you when you are logged in;</li>
        <li>to provide accessibility options</li>
      </ul>
      <h3>5.6 Performance and analysis cookies</h3>
      <p>We use performance/analysis cookies to analyze how our website is accessed, used or functions in order to provide you with a better user experience and to maintain, operate and continually improve our website. These cookies enable us, for example:</p>
      <ul>
        <li>to better understand visitors to our website so that we can improve the presentation of our content;</li>
        <li>to test different design concepts for certain pages, like our homepage;</li>
        <li>to collect information about visitors to our website, such as their location and the browser they use;</li>
        <li>to determine the number of unique users on our website;</li>
        <li>to improve our website by logging all errors that have occurred;</li>
        <li>to conduct research and error diagnosis in order to improve the product range.</li>
      </ul>
      <p>We use Google Analytics and many other web analytics tools to help us better understand how users use the site. Like many other services, these web analytics services use first-party cookies to track users’ activity. In our case, they are used to collect information about how users use our Sites. This information is used to generate reports and create services that help us improve our Sites and the services we provide through it. The reports inform us about website trends without identifying individual visitors. For some services, you may opt out of tracking immediately. For example, you can disable the use of cookies by our website or Google Analytics without affecting how you visit our website.</p>
      <h3>5.7 Advertising and Tracking Cookies</h3>
      <p>We may allow third parties, including advertisers (such as Google), to place cookies on our website. These cookies allow these companies to track your activities on various websites where they serve ads and record your activities so they can serve ads that they think are relevant to you while you browse the Internet. These cookies store information about the content you are browsing, along with an identifier associated with your device or IP address. These cookies also allow us and third parties to know whether you have seen an ad or a type of ad and how long ago you last saw it. This information is used for frequency limiting purposes, to customize the ads you display, and to measure the effectiveness of ads.</p>
      <h3>5.8 Third Party Cookies</h3>
      <p>There are some pages on our website where third parties provide applications with their own cookies to track the success of their services (applications) or customize applications for you. Due to the way these cookies work, we cannot access the cookies, nor can third parties access the data of the cookies we use. Some pages of our website may also contain embedded content, and these third party websites may set their own cookies.</p>
      <h3>5.9 How to control or delete cookies</h3>
      <p>You have the right to accept or stop the storage of cookies on your device at any time by adjusting the settings in your web browser to your cookie settings. Please note that if cookies are disabled, you may not be able to use all interactive features of the website and/or online courses and content.</p>
      <p>Most browsers provide instructions on how to change your cookie settings. These settings are usually found in the Options or Settings menu of your browser.</p>
      <h3>5.8 Cookies that have been stored in the past</h3>
      <p>If you have disabled one or more cookies, we may still use information collected from cookies before your setting was disabled. However, we will no longer use the disabled cookie to collect further information.</p>
    <h2>6. How long do we keep your information</h2>
    <p>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us. When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further
processing until deletion is possible.</p>
<h2>7. How do we keep your information safe?</h2>
<p>We aim to protect your personal information through a system of organizational and technical security measures. We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Platform is at your own risk. You should only access the services within a secure environment.</p>
<h2>8. Do we collect information from minors?</h2>
<p>We do not knowingly collect data from, solicit or market to children under 13 years of age. By using the Travel Afrique Platform, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Platform. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 13, please contact us via contact(at)travelafrique.com</p>
<h2>9. What are your privacy rights?</h2>
<p>In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time. In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided in the last section.</p>
<p>We will consider and act upon any request in accordance with applicable data protection laws. If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal. If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">here</a>.</p>
<h2>10. Controls for do-not-track features</h2>
<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
<h2>11. Do we make updates to this Privacy Policy?</h2>
<p>Yes, we will update this policy as necessary to stay compliant with relevant laws. We may update this Privacy Policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this Privacy Policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Policy frequently to be informed of how we are protecting your information.</p>
<h2>12. How can you contact us about this Policy?</h2>
<p>If you have any questions about this Policy, please email us via contact(at)traveafrique.com</p>
</div>
    </main>
    <Footer />
    <BottomBar class="block sm:hidden fixed bottom-0 w-full" />
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import BottomBar from "@/components/layout/BottomBar.vue";
export default { name: "Privacy", components: { Navbar, Footer, BottomBar } };
</script>
<style scoped>
.page-main {
  @apply w-full px-4 pt-36 pb-16;
}

.wrapper {
  @apply w-full max-w-screen-sm;
  @apply relative left-1/2 transform -translate-x-1/2;
}
ul {
  @apply text-black-base;
}

ul li {
  @apply list-item ml-8 list-disc whitespace-pre-wrap my-1;
}
h1,
h2,
h3 {
  @apply font-semibold text-black-base;
}

p {
  @apply text-black-base leading-normal;
  @apply mt-2 mb-4;
}

h1 {
  @apply text-3xl leading-tight;
}

h2 {
  @apply text-2xl leading-9;
  @apply mt-8 mb-2;
}

h3 {
  @apply mt-4 mb-2 text-lg;
}

.updated-at {
  @apply text-black-lighter text-sm;
}
</style>